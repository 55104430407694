<template lang="html">
  <div ref="container"
       class="building"
  />
</template>

<script>
const THREE = require('three');
export default {
  props: {
    buildingUUID: {
      type: String,
      default: '',
    },
    buildingShape: {
      type: Object,
      default: null,
    },
    preferences: {
      type: Object,
      default: null,
    },
    vHeight: {
      type: Number,
      default: 200,
    },
    vWidth: {
      type: Number,
      default: 250,
    },
    colors: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      renderer: null,
      scene: null,
      camera: null,
      group: null,
      light: null,
      reflect: null,
      mouse: null,
      mouseHover: false,
      mouseDown: false,
      raycaster: null,
      centerPoint: null,
      intersected: null,
      floorBasicColor: '#ded9d2',
      subFloorBasicColor: '#555555',
      colorSelection: '#ffffff',
      meshes: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    canOrientBuilding() {
      return this.user.featuresRights.buildingOrientation.WRITE;
    }
  },
  watch: {
    colors: {
      handler(val) {
        this.updateColors();
        this.animate();
      },
      deep: true,
    },
  },
  mounted() {
    if (this.buildingShape) {
      this.init();
      this.animate();
    }
  },
  methods: {
    getFloorColor(idx, elevation) {
      // GET COLOR FROM FLOOR SELECTION OR CURRENT OAPP COLOR
      const color = elevation >= 0 ? this.floorBasicColor : this.subFloorBasicColor;
      return this.colors && this.colors[idx] ? this.colors[idx].toString() : color;
    },
    updateColors() {
      this.meshes.forEach(m => {
        m.mesh.material.color.setStyle(this.getFloorColor(m.idx, m.elevation));
      });
    },
    build() {
      this.group = new THREE.Group();
      let zeroFloorSet = false;
      this.buildingShape.contour.forEach((floor, idx) => {
        const vectors = [];
        if (floor.points.length === 0) return;
        floor.points.map(points => {
          vectors.push(
            ...points.map(p => {
              return new THREE.Vector2(p[0] - this.centerPoint[0], p[1] - this.centerPoint[1]);
            })
          );
        });
        const depth = this.buildingShape.contour[idx + 1]
          ? (this.buildingShape.contour[idx + 1].elevation - floor.elevation) / 1.5
          : this.buildingShape.contour[idx - 1]
            ? (floor.elevation - this.buildingShape.contour[idx - 1].elevation) / 1.5
            : 5000;
        let mesh = null;
        if (
          ((this.buildingShape.contour[idx - 1] && this.buildingShape.contour[idx - 1].elevation < 0) ||
            !this.buildingShape.contour[idx - 1]) &&
          floor.elevation >= 0
        ) {
          const zeroGeometry = new THREE.ExtrudeBufferGeometry(new THREE.Shape(vectors), {
            depth: depth / 8,
            bevelEnabled: true,
            bevelSegments: 1,
            steps: 0,
            bevelSize: 0,
            bevelThickness: 0,
          });
          mesh = new THREE.Mesh(zeroGeometry, new THREE.MeshLambertMaterial({ color: 0x000000 }));
          mesh.position.set(0, 0, (floor.elevation / 1.5) * 1.1);
          mesh.scale.set(1, 1, 1);
          mesh.castShadow = true;
          this.group.add(mesh);
          zeroFloorSet = true;
        }
        if (vectors.length === 0) return;
        const geometry = new THREE.ExtrudeBufferGeometry(new THREE.Shape(vectors), {
          // DEPTH COMPUTED USING ELEVATION OF EACH FLOOR - TOP FLOOR DEPTH IS THE SAME DEPTH AS THE BEFORE LAST
          depth: depth,
          bevelEnabled: true,
          bevelSegments: 1,
          steps: 0,
          bevelSize: 0,
          bevelThickness: 0,
        });
        mesh = new THREE.Mesh(geometry, new THREE.MeshLambertMaterial({ color: this.getFloorColor(idx, floor.elevation) }));
        mesh.position.set(0, 0, (floor.elevation / 1.5) * 1.1 + (zeroFloorSet ? (depth / 8) * 1.1 : 0));
        mesh.scale.set(1, 1, 1);
        mesh.castShadow = true;
        this.meshes.push({ mesh, idx, elevation: floor.elevation });
        this.group.add(this.meshes[idx].mesh);
      });
      this.scene.remove(this.group);
      this.scene.add(this.group);
      this.group.updateMatrix();
    },
    init() {
      // const oldLogFunction = console.log;
      // console.log = function() {};
      //GET CONTAINER AND INIT SCENE
      const container = this.$refs.container;
      this.centerPoint = this.buildingShape.center_point;
      let total = 0;
      this.buildingShape.contour.map(floor => floor.points).forEach(elt => elt.forEach(elt2 => (total += elt2.length)));

      this.totalPolygon = 0;
      for (const storey of this.buildingShape.contour) {
        this.totalPolygon += storey.nsubpolygons;
      }
      this.totalPoints = total;
      this.scene = new THREE.Scene();

      //INIT GROUP
      this.group = new THREE.Group();
      this.group.castShadow = true;
      this.group.receiveShadow = true;
      this.group.matrixAutoUpdate = false;

      //BUILD BUILDING FROM DATA
      this.build();
      this.group.scale.set(1, 1, 1);
      this.scene.add(this.group);

      const groupSize = new THREE.Box3().setFromObject(this.group).getSize(this.group.position);
      const groupCenter = new THREE.Box3().setFromObject(this.group).getCenter(this.group.position);

      // REFLECT LIGHT
      this.light = new THREE.DirectionalLight(0xffffff, 0.15);
      this.light.position.set(groupCenter.x + groupSize.x * 2, groupCenter.y, groupCenter.z);
      this.light.position.x;
      this.light.castShadow = true;
      this.light.target.position.set(groupCenter.x + groupSize.x / 2, groupCenter.y + groupSize.y / 2, groupCenter.z + groupSize.z);
      this.light.target.updateMatrixWorld();
      this.scene.add(this.light);
      this.scene.add(this.light.target);

      // AMBIENT LIGHT
      const alight = new THREE.AmbientLight(0xffffff, 0.7);
      this.scene.add(alight);

      // MAIN LIGHT
      this.reflect = new THREE.DirectionalLight(0xffffff, 0.4);
      this.reflect.target.position.set(groupCenter.x + groupSize.x / 2, groupCenter.y + groupSize.y / 2, groupCenter.z + groupSize.z);
      this.reflect.target.updateMatrixWorld();
      this.reflect.position.set(groupCenter.x + groupSize.x, groupCenter.y - groupCenter.y * 2, groupCenter.z);
      this.reflect.castShadow = true;
      this.scene.add(this.reflect);
      this.scene.add(this.reflect.target);

      // BACK LIGHT
      const back = new THREE.DirectionalLight(0xffffff, 0.3);
      back.target.position.set(groupCenter.x + groupSize.x / 2, groupCenter.y + groupSize.y * 2, groupCenter.z + groupSize.z);
      back.target.updateMatrixWorld();
      back.position.set(groupCenter.x - groupSize.x * 2, groupCenter.y + groupCenter.y * 2, groupCenter.z);
      back.castShadow = true;
      this.scene.add(back);
      this.scene.add(back.target);

      // CAMERA SETTINGS
      const aspect = this.vWidth / this.vHeight;
      // MaxSize is the max value of the x and z sizes of the building with a multiplier offset to get a "padding"
      const maxSize = ((groupSize.x + groupSize.z) / 2) * 1.75;
      this.camera = new THREE.OrthographicCamera(
        -aspect * (maxSize / 2),
        aspect * (maxSize / 2),
        maxSize / 2,
        -maxSize / 2,
        1,
        groupSize.y * 10
      );
      this.camera.updateProjectionMatrix();
      const camera_minibuilding = this.preferences && this.preferences.camera_minibuilding;
      if (camera_minibuilding) {
        this.camera.position.set(camera_minibuilding.x, camera_minibuilding.y, camera_minibuilding.z);
      } else {
        this.camera.position.set(
          groupCenter.x + groupSize.x * 3,
          groupCenter.y - groupSize.y * 3,
          groupCenter.z + Math.sqrt(groupSize.x * groupSize.y)
        );
      }
      this.scene.add(this.camera);

      //MOUSE AND RAYCASTER
      this.mouse = new THREE.Vector2();
      this.raycaster = new THREE.Raycaster();

      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      this.renderer.setClearColor(0x0000000, 0);
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(this.vWidth, this.vHeight);
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      container.appendChild(this.renderer.domElement);
      container.addEventListener('mousedown', this.onMouseDown, false);
      container.addEventListener('mouseup', this.onMouseUp, false);
      container.addEventListener('mousemove', this.onMouseMove, false);
      container.addEventListener('mouseleave', this.onMouseLeave, false);
      container.addEventListener(
        'contextmenu',
        function(evt) {
          evt.preventDefault();
        },
        false
      );
      // console.log = oldLogFunction;
    },
    // CLICK EVENT
    onMouseDown(event) {
      event.preventDefault();
      this.mouseDown = true;
      this.mouse.x = (event.offsetX / this.vWidth) * 2 - 1;
      this.mouse.y = -(event.offsetY / this.vHeight) * 2 + 1;
      this.raycaster.setFromCamera(this.mouse, this.camera);
      const intersects = this.raycaster.intersectObjects(this.group.children);
      if (intersects.length > 0) {
        if (event.which === 3) this.$emit('rightClick');
        else this.$emit('click');
      }
    },
    async onMouseUp(event) {
      event.preventDefault();
      this.mouseDown = false;
      const camera_minibuilding = this.preferences && this.preferences.camera_minibuilding;
      const camera = this.camera && this.camera.position;
      if (
        !camera_minibuilding ||
        (camera_minibuilding &&
          camera &&
          (camera_minibuilding.x !== camera.x || camera_minibuilding.y !== camera.y || camera_minibuilding.z !== camera.z))
      ) {
        await this.$store.dispatch('building/updatePreferences', { uuid: this.buildingUUID, preferences: { ...this.preferences, camera_minibuilding: camera } });
      }
    },
    // HOVER EVENT
    onMouseMove(event) {
      event.preventDefault();
      this.mouse.x = (event.offsetX / this.vWidth) * 2 - 1;
      this.mouse.y = -(event.offsetY / this.vHeight) * 2 + 1;
      this.raycaster.setFromCamera(this.mouse, this.camera);
      const intersects = this.raycaster.intersectObjects(this.group.children);

      if (!this.mouseHover && intersects.length > 0) {
        this.mouseHover = true;
        this.lightUpdate();
      } else if (intersects.length === 0 && this.mouseHover) {
        this.mouseHover = false;
        this.lightUpdate();
      }
      if (this.mouseDown && this.canOrientBuilding) {
        const limit = 0.1;

        const stepX = this.buildingShape.center_point[0] * Math.abs(event.movementX / 10); //0.3;
        const stepY = this.buildingShape.center_point[0] * Math.abs(event.movementY / 10); //0.3;

        const movementX = event.movementX > limit ? stepX : event.movementX < -limit ? -stepX : 0;
        const movementY = event.movementY > limit ? stepY : event.movementY < -limit ? -stepY : 0;

        this.camera.position.set(this.camera.position.x, this.camera.position.y + -movementX, this.camera.position.z + movementY);
        this.animate();
      }
    },
    onMouseLeave(event) {
      event.preventDefault();
      this.mouseHover = false;
      this.mouseDown = false;
      this.lightUpdate();
    },
    lightUpdate() {
      if (this.mouseHover) {
        this.light.intensity = 0.3;
        this.reflect.intensity = 0.5;
      } else {
        this.light.intensity = 0.15;
        this.reflect.intensity = 0.4;
      }
      this.animate();
    },
    animate() {
      this.camera.up = new THREE.Vector3(0, 0, 1);
      const size = new THREE.Box3().setFromObject(this.group).getSize(this.group.position);
      this.camera.lookAt(new THREE.Vector3(this.group.position.x, this.group.position.y, this.group.position.z + size.z / 2));
      this.camera.updateProjectionMatrix();
      this.camera.updateMatrixWorld();
      this.renderer.render(this.scene, this.camera);
    },
  },
};
</script>

<style lang="stylus">
.building
  margin 0
  width 100%
</style>
